import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
const Login = (props) => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [showPaassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setLogin({ ...login, [e.target.name]: e.target.value });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const response = await fetch(
      "https://ed-tech-backend-fsnw.onrender.com/api/v1/user/login/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: login.email, password: login.password }),
      }
    );
    const res = await response.json();
    if (login.email === process.env.REACT_APP_ADMIN_EMAIL) {
      if (login.password === process.env.REACT_APP_ADMIN_PASSWORD) {
        window.location.href = process.env.REACT_APP_ADMIN_URL;
        return setSpinner(false);
      }
    }
    if (res.success) {
      localStorage.setItem("authToken", res.data.token);
      props.showAlert(res.message, "success");
      navigate("/");
    } else {
      props.showAlert(res.message, "error");
    }
    setSpinner(false);
  };
  return (
    <div className="main-container">
      {spinner && <Spinner />}
      <div className="login_img">
        <img src={require("../Image/img1.png")} alt="" />
      </div>
      <div className="form-container login">
        <form id="login-form" className="form" onSubmit={submitForm}>
          <p className="form-head">Sign In</p>
          <p className="register_note">
            {" "}
            <span>Note:</span> If you haven't registered, please register first.
          </p>
          <input
            type="text"
            name="email"
            id="email"
            value={login.email}
            placeholder="Email or Username"
            onChange={onChange}
            required
          />
          <div className="password-input">
            <input
              type={showPaassword ? "text" : "password"}
              name="password"
              value={login.password}
              id="password"
              placeholder="Password"
              onChange={onChange}
              required
            />
            <div className="show-password">
              {showPaassword ? (
                <i
                  className="fa-regular fa-eye"
                  onClick={() => setShowPassword(!showPaassword)}
                ></i>
              ) : (
                <i
                  className="fa-regular fa-eye-slash"
                  onClick={() => setShowPassword(!showPaassword)}
                ></i>
              )}
            </div>
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
          <p className="login-text">
            Don't have an Account?{" "}
            <Link to="/sign-up" className="link">
              Create New Account
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Login;
