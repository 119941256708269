import React, { useState, useEffect } from 'react';
import '../CSS/Testimonials.css'

const Testimonials = () => {
  const [index, setIndex] = useState(0);

  const testimonials = [
    {
      text: "I really enjoyed the courses on this platform. They were well-structured and easy to follow. The interactive quizzes helped me understand the concepts better.",
      author: "- John Doe"
    },
    {
      text: "The instructors are knowledgeable and engaging. I learned a lot from the live sessions and found the projects to be challenging and rewarding.",
      author: "- Jane Smith"
    },
    {
      text: "The platform offers a wide range of courses that are relevant to my field. I appreciate the flexibility to learn at my own pace and the quality of the content.",
      author: "- Alex Johnson"
    }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 3000); 
    return () => clearInterval(interval);
  }, []);

  const showTestimonial = (index) => {
    setIndex(index);
  };

  return (
    <div className="header">
      <div className="inner-header flex">
        <h2>Student Testimonials</h2>
        <div className="testimonial-container">
          {testimonials.map((testimonial, idx) => (
            <div key={idx} className={`testimonial ${idx === index ? 'active' : ''}`}>
              <p>{testimonial.text}</p>
              <span className="author">{testimonial.author}</span>
            </div>
          ))}
        </div>
        <div className="dots-container">
          {testimonials.map((_, idx) => (
            <span key={idx} className={`dot ${idx === index ? 'active' : ''}`} onClick={() => showTestimonial(idx)}></span>
          ))}
        </div>
      </div>
      <div>
        <svg className="waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
          <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
          </defs>
          <g className="parallax">
            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Testimonials;
