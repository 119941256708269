import React, { useState } from "react";
import Navbar from "./Component/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DiscountHeader from "./Component/DiscountHeader";
import Home from "./Component/Home";
import About from "./Component/About";
import Login from "./Component/Login";
import SignUp from "./Component/SignUp";
import EachCourse from "./Component/EachCourse";
import Lessons from "./Component/Lessons";
import Alert from "./Component/Alert";
import Notes from "./Component/Notes";
import Porfile from "./Component/Porfile";
import AllCoursePage from "./Component/AllCoursePage";
import EachLiveCourse from "./Component/EachLiveCourse";
import CoursePriceDetails from "./Component/CoursePriceDetails";
import ConfirmPayment from "./Component/ConfirmPayment";
import AllLiveCourse from "./Component/AllLiveCourse";
import LiveCoursePriceDetails from "./Component/LiveCoursePriceDetails";
import Event from "./Component/Event";
const App = () => {
  const [alert, setAlert] = useState(null);
  const showAlert = (msg, type) => {
    setAlert({
      msg: msg,
      type: type,
    });
    setTimeout(() => {
      setAlert(null);
    }, 3000);
  };
  return (
    <>
      <BrowserRouter>
        <Alert alert={alert} />
        <DiscountHeader />
        <Navbar showAlert={showAlert} />
        <Routes>
          <Route exact path="/" element={<Home showAlert={showAlert} />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login showAlert={showAlert} />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/course/:CourseId"
            element={<EachCourse showAlert={showAlert} />}
          />
          <Route
            path="/live-course/:CourseId"
            element={<EachLiveCourse showAlert={showAlert} />}
          />
          <Route
            path="/enrolled-course/:CourseId"
            element={<Lessons showAlert={showAlert} />}
          />
          <Route path="/notes" element={<Notes />} />
          <Route path="/profile" element={<Porfile showAlert={showAlert} />} />
          <Route
            path="/courses"
            element={<AllCoursePage showAlert={showAlert} />}
          />
          <Route
            path="/selected-course/:id"
            element={<CoursePriceDetails showAlert={showAlert} />}
          />
          <Route
            path="/selected-live-course/:id"
            element={<LiveCoursePriceDetails showAlert={showAlert} />}
          />
          <Route
            path="/confirm-payment/:id"
            element={<ConfirmPayment showAlert={showAlert} />}
          />
          <Route
            path="/live-course"
            element={<AllLiveCourse showAlert={showAlert} />}
          />
          <Route path="/event" element={<Event showAlert={showAlert} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
