import React from 'react'
import '../CSS/Category.css'
import { Link, useNavigate } from 'react-router-dom'
const Category = () => {
    const navigate = useNavigate();

    return (

        <div class="category">
            <div class="categories">
                <div class="cat" onClick={()=>navigate('/live-course')} style={{cursor:'pointer'}}>
                    <div class="catimg" >
                        <img src={require("../Image/cat1.png")} alt="liveSessions" />
                    </div>
                    <h1>Daily Live Sessions</h1>
                    <p>Interactive live classes</p>
                </div>
                <a href="#notes">
                <div class="cat">
                    <div class="catimg">
                        <img src={require("../Image/cat2.png")} alt="liveSessions" />
                    </div>
                    <h1>10+ Million Notes</h1>
                    <p>Notes, Test and Sample papers</p>
                </div>
                </a>
                <a href='https://forms.gle/ZgNV2HFB9n99K8Wi8' target='_singh'>
                <div class="cat">
                    <div class="catimg">
                        <img src={require("../Image/cat3.png")} alt="liveSessions" />
                    </div>
                    <h1>24/7</h1>
                    <p>Doubt Solving Sessions</p>
                </div>
                </a>
                <a href="https://forms.gle/ngaAcZYLJHAk3LUAA" target='_singh'>
                <div class="cat4">
                    <div class="catimg">
                        <img src={require("../Image/cat4.png")} alt="liveSessions" />
                    </div>
                    <h1>Offline Centers</h1>
                    <p>Offline Batches Available</p>
                </div>
                </a>
            </div>
        </div>

    )
}

export default Category