import React from 'react'
import '../CSS/lResoource.css'
import { Link } from 'react-router-dom'
const LResource = () => {
  return (
    <>
     <div className="lresource" id='notes'>
        <div className="upper_content catitems">
            <h1>Learning Resources</h1>
            <hr />
        </div>

        <div className="resource_boxes">
            <div className="boxofresource">
            <Link to='/notes'>
                <h1>Video Lectures</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae tempora totam quis, nisi fugit
                    incidunt!</p>
                <img src={require("../Image/video_lecture.png")} alt="" />
                </Link>
            </div>
            <div className="boxofresource">
                <Link to='/notes'>
                <h1>Notes</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae tempora totam quis, nisi fugit
                    incidunt!</p>
                <img src={require("../Image/video_lecture.png")} alt="" />
                </Link>
            </div>
            <div className="boxofresource">
            <Link to='/notes'>
                <h1>Reference Books</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae tempora totam quis, nisi fugit
                    incidunt!</p>
                <img src={require("../Image/video_lecture.png")} alt="" />
                </Link>
            </div>
        </div>
    </div>
   
    </>
  )
}

export default LResource