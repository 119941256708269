import React from 'react'
import '../CSS/Footer.css'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer">
            <div className="row">
                <div className="col">
                    <img src={require('../Image/logo.png')} alt="" />
                </div>
                <div className="col">
                    <h3>
                        Contacts
                        <div className="underline"><span></span></div>
                    </h3>

                    <p>C/O,1225/a, Fc Road, Biwalkar Wada, Deccan Gymkhana</p>
                    <p>Pune City, Pune, Maharashtra, 411004</p>
                    <p className="emailid">Email : tanvideshpandeacademy@gmail.com</p>
                    <h4 id="sixw">Phone : +91 9225523545</h4>
                </div>
                {/* <div className="col">
                    <h3>Google Map
                        <div className="underline" ><span></span> </div></h3>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14020.939300869439!2d77.25367914167525!3d28.53266066317493!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce15ffaaa8b7b%3A0xc48acec695cfc504!2sGovindpuri%2C%20New%20Delhi%2C%20Delhi%20110019!5e0!3m2!1sen!2sin!4v1713712379143!5m2!1sen!2sin"
                        width="300" height="200" style={{ "border": 0 }} allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div> */}
                <div className="col">
                    <h3>Connect<div className="underline"><span></span></div></h3>
                    <p>Bridge between student and institutes</p>
                    <div className="button"><a href="https://wa.me/9225523545" target='_blank'>Click</a></div>
                    <div><img src={require("../Image/QR.png")} alt="" id="scanner" /></div>
                </div>

                <div className="col">
                    <h3>Menus<div className="underline"><span></span></div></h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><Link to='/about' loading="lazy">About</Link></li>
                        <li><Link to='/#notes' loading="lazy">Team</Link></li>
                        <li><Link to='/event' loading="lazy">News & Events</Link></li>
                        <li><Link to='/about' loading="lazy">Privacy Policy</Link></li>
                        <li><Link to='/about' loading="lazy">Contact Us</Link></li>
                        
                        
                    </ul>
                </div>
            </div>
            <div className="main-media">
                <ul className="media">
                    <li>
                        <a href="https://instagram.com/deutschify" target='_blank'> </a>
                        <i className="fab fa-instagram"></i>
                    </li>
                    {/* <li>
                        <a href="https://www.facebook.com/edtech/"></a>
                        <i className="fab fa-facebook-f"></i>
                    </li> */}
                    {/* <li>
                        <a href="https://twitter.com/edtech/">
                            <i className="fab fa-twitter"></i>
                        </a>
                    </li> */}
                    <li>
                        <a href="https://www.linkedin.com/company/deutschify/" target='_blank'>
                            <i className="fab fa-linkedin-in"></i>
                        </a>
                    </li>

                    <li>
                        <a href="https://youtube.com/@tanvi__2722?si=FNyBAXCqM9lN6aHk" target='_blank'>
                            <i className="fab fa-youtube"></i></a>
                    </li>
                </ul>
            </div>
            <hr />
            <div className="copyright">
                <p>Copyright © 2024 Tanvi Deshpande Academy. All Rights Reserved.</p>
                <p>Presented By <a href='https://memeshots.in/' target='_blank'>Memeshots</a></p>
            </div>
        </div>
    )
}

export default Footer