import React, { useEffect, useState } from "react";
import "../CSS/Profile.css";
import Spinner from "./Spinner";
import LimitWords from "./LimitWords";
import { Link } from "react-router-dom";
const Profile = (props) => {
  const [spinner, setSpinner] = useState(false);
  const [progressPercentages, setProgressPercentages] = useState({});
  const [enrolledCourse, setEnrolledCourse] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  const [currentCourseId, setCurrentCourseId] = useState(null);
  const [details, setDetails] = useState({
    prfileId: "",
    fullName: "",
    number: "",
    email: "",
    profile: "",
  });
  const callApiProfile = async () => {
    setSpinner(true);
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/user/get-user`,
      {
        headers: {
          authToken: localStorage.getItem("authToken"),
        },
      }
    );
    const resData = await response.json();
    const data = resData.data;
    setDetails({
      prfileId: data._id,
      fullName: data.fullName,
      email: data.email,
      number: data.number,
      profile: data.profile,
    });
    setSpinner(false);
  };

  const callEnrolledCourse = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/user/enrolled-courses`,
      {
        headers: {
          authToken: localStorage.getItem("authToken"),
        },
      }
    );

    const res = await response.json();
    setEnrolledCourse(res.data);
  };
  useEffect(() => {
    callApiProfile();
    callEnrolledCourse();
  }, []);

  const onFileChange = async (e) => {
    setSpinner(true);
    const uploadedImage = e.target.files[0];
    const formData = new FormData();
    formData.append("profile", uploadedImage);

    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/user/profile/${details.prfileId}`,
      {
        method: "POST",
        body: formData,
      }
    );
    const json = await response.json();
    setSpinner(false);
    props.showAlert("Successfully Updated Image", "success");
    callApiProfile();
  };
  const [hovered, setHovered] = useState(false);

  // For Score

  const checkPorgress = async (courseId) => {
    let totalMarks = 0;
    let obtainedMarks = 0;
    const fecthTotalMarks = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/quiz/get-all-quiz-from-course`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ courseId }),
      }
    );
    const res = await fecthTotalMarks.json();
    const resFecthTotalMarks = res.data;
    resFecthTotalMarks.map((elem) => {
      totalMarks += elem.questions.length;
    });

    const fetchObtainMarks = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/score/get-all-score`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authToken: localStorage.getItem("authToken"),
        },
        body: JSON.stringify({ courseId }),
      }
    );
    const obtainedRes = await fetchObtainMarks.json();
    const resObtainedMarks = await obtainedRes.data;
    resObtainedMarks.map((elem) => {
      obtainedMarks += elem.score;
    });
    const percentage = (obtainedMarks / totalMarks) * 100;
    setProgressPercentages({
      ...progressPercentages,
      [courseId]: percentage,
    });
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="profile">
        <div className="left-profile">
          <h1>Welcome {details.fullName}!</h1>
          <p>Always Stay updated in your study platform</p>
        </div>
        <div className="right-profile">
          <div
            className="image-box"
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <img
              src={
                details.profile
                  ? details.profile
                  : require("../Image/profile.webp")
              }
              alt="Image"
            />
            {hovered && (
              <div className="overlay">
                <label htmlFor="profile">Change Image</label>
                <input
                  type="file"
                  name="profile"
                  onChange={onFileChange}
                  id="profile"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="enrolled-course">
        <h1>Enrolled Course</h1>
        <div className="course-section">
          {enrolledCourse.length>0 ?
            enrolledCourse.map((elem, id) => {
              return (
                <div key={id}>
                  <div className="course">
                    <img src={elem.coverImage} alt="" />
                    <h2>{elem.courseName}</h2>
                    <LimitWords text={elem.description} limit={10} />
                    <div
                      className="course-buttons"
                      style={{ display: "flex", gap: "1px" }}
                    >
                      <Link to={`/enrolled-course/${elem._id}`}>
                        <button>See Course</button>
                      </Link>
                      <Link
                        onClick={() => {
                          setShowProgress(true);
                          setCurrentCourseId(elem._id);
                          checkPorgress(elem._id);
                        }}
                      >
                        <button>Check Progres</button>
                      </Link>
                    </div>
                    {showProgress && currentCourseId === elem._id && (
                      <ProgressBar
                        percentage={progressPercentages[elem._id] || 0}
                      />
                    )}
                  </div>
                </div>
              );
            }):<h3 style={{color:'black'}}>No Enrolled Course Found !!</h3>}
        </div>
      </div>
          </>
  );
};

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar"
        style={{
          width: `${percentage}%`,
          backgroundColor: getColor(percentage),
        }}
      >
        <span className="progress-text">{`${percentage}%`}</span>
      </div>
    </div>
  );
};

// Function to determine color based on percentage
const getColor = (percentage) => {
  if (percentage >= 90) {
    return "#2ecc71"; // Green color for high progress
  } else if (percentage >= 50) {
    return "#f39c12"; // Orange color for moderate progress
  } else {
    return "#e74c3c"; // Red color for low progress
  }
};
export default Profile;
