import React from 'react'
import '../CSS/HeroSection.css'
const HeroSection = () => {
  return (
    <div className="hero_section">
      <div className="leftHero">
        <h1>From A to Z : <span className="typing">Your Ultimate Educational Companion! </span> </h1>
        <a href="#live-course"><button>Get Started</button></a>
      </div>
      <div className="rightHero">
        <img src={require("../Image/school-education.gif")} alt="books Pic" />
      </div>
    </div>
  )
}

export default HeroSection