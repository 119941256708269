import React, { useEffect, useState } from "react";
import "../CSS/Lesson.css";
import Footer from "./Footer";
import "../CSS/EachQuiz.css";
import Spinner from './Spinner'
import { Link, useNavigate } from "react-router-dom";
const Lessons = (props) => {
  const [showLesson, setShowLesson] = useState(false);
  const CourseId = window.location.pathname.split("/").pop();
  const [lessonDetails, setLessonDetails] = useState({
    lessonIndex: "",
    lessonTitle: "",
    lessonContent: "",
  });

  const getLessonDetails = async (id) => {
    try {
      const response = await fetch(
        `https://ed-tech-backend-fsnw.onrender.com/api/v1/lesson/get-lesson/${id}`
      );
      const res = await response.json();
      const data = res.data[0];
      setShowLesson(true);
      setShowQuiz(false);
      setLessonDetails({
        lessonIndex: data.lessonIndex,
        lessonTitle: data.lessonTitle,
        lessonContent: data.lessonContent,
      });
    } catch (error) {
      console.error("Error fetching lesson details:", error);
    }
  };

  useEffect(() => {
    getChapterDetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // For all chapters and subb chapters
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSubChapterIndex, setSubChapterActiveIndex] = useState(null);
  const [quizDetails, setquizDetails] = useState([]);
  const [chapterDetails, setChapterDetails] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [subChapter, setSubChapter] = useState([]);
  const [lesson, setLesson] = useState([]);
  const toggleAccordion = (index, id) => {
    getSubChapterDetails(id);
    setActiveIndex(activeIndex === index ? null : index);
  };
  const toggleSubChapterAccordion = (index, id) => {
    getLesson(id);
    getQuiz(id);
    setSubChapterActiveIndex(activeSubChapterIndex === index ? null : index);
  };
  const getChapterDetails = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/chapter/get-all-chapter/${CourseId}`
    );
    const res = await response.json();
    const data = res.data;
    setChapterDetails(data);
  };
  const getSubChapterDetails = async (id) => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/sub-chapter/get-all-chapter/${id}`
    );
    const res = await response.json();
    const data = res.data;
    setSubChapter(data);
  };
  const getLesson = async (id) => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/lesson/get-all-lesson/${id}`
    );
    const res = await response.json();
    const data = res.data;
    setLesson(data);
    localStorage.setItem("lesson", lesson);
  };
  const getQuiz = async (id) => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/quiz/get-all-quiz/${id}`
    );
    const res = await response.json();
    const data = res.data;
    setquizDetails(data);
  };

  // For quiz
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [data, setData] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const [showquiz, setShowQuiz] = useState(false);

  const fetchQuestions = async (QuizId) => {
    try {
      const response = await fetch(
        `https://ed-tech-backend-fsnw.onrender.com/api/v1/quiz/get-quiz/${QuizId}`
      );
      const res = await response.json();
      console.log(res)
      setLessonDetails("");
      setShowQuiz(true);
      setShowLesson(false);
      setQuestions(res.data[0].questions);
      setData(res.data[0]);
      setUserAnswers(Array(res.data[0].questions.length).fill(""));
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleAnswerChange = (index, answer) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = answer;
    setUserAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    setSpinner(true)
    let newScore = 0;
    questions.forEach((question, index) => {
      if (userAnswers[index] === data.answers[index]) {
        newScore++;
      }
    });

    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/score`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          authToken: localStorage.getItem("authToken"),
        },
        body: JSON.stringify({
          quizId: data._id,
          courseId: CourseId,
          score: newScore,
          totalMarks: questions.length,
        }),
      }
    );
    const res = await response.json();
    setSpinner(false)
    alert("Your Score is " + newScore);
    props.showAlert(res.message, "success");
    navigate('/profile')
  };

  return (
    <>
    {spinner&&<Spinner/>}
      <div className="all-lessons">
        <div className="left-lesson">
          <div className="each-course-accordion">
            {chapterDetails.map((elem, index) => {
              return (
                <AccordionItem
                  title={`${elem.title}`}
                  isOpen={activeIndex === index}
                  onClick={() => toggleAccordion(index, elem._id)}
                >
                  {subChapter.map((elem, index) => {
                    return (
                      <ul>
                        <AccordionItem
                          title={`${elem.subChapterTitle}`}
                          isOpen={activeSubChapterIndex === index}
                          onClick={() =>
                            toggleSubChapterAccordion(index, elem._id)
                          }
                        >
                          {lesson.map((elem) => {
                            return (
                              <ul>
                                <Link
                                  onClick={() => getLessonDetails(elem._id)}
                                >
                                  <li>{elem.lessonTitle}</li>
                                </Link>
                              </ul>
                            );
                          })}
                          {quizDetails.map((elem) => {
                            return (
                              <ul>
                                <Link onClick={() => fetchQuestions(elem._id)}>
                                  <li>
                                    {elem.quizIndex} - {elem.quizTitle}
                                  </li>
                                </Link>
                              </ul>
                            );
                          })}
                        </AccordionItem>
                      </ul>
                    );
                  })}
                </AccordionItem>
              );
            })}
          </div>
        </div>
        <div className="right-lesson">
          {lessonDetails && (
            <div
              style={{
                boxShadow: "0px 0px 8px black",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              {" "}
              <h1>
                {lessonDetails.lessonIndex} - {lessonDetails.lessonTitle}
              </h1>
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {lessonDetails.lessonContent}
              </pre>
            </div>
          )}
          {showquiz && (
            <>
              <div className="quiz">
                <h1>
                  {data.quizIndex}- {data.quizTitle}
                </h1>
                <div className="all-questions">
                  {questions.map((question, index) => (
                    <div key={index} className="question">
                      <h3>
                        Question {index + 1}:- {question.question}
                      </h3>
                      <div className="all-options">
                        {question.options.map((option, optionIndex) => (
                          <div key={optionIndex} className="options">
                            <input
                              type="radio"
                              id={`option${optionIndex}`}
                              name={`question${index}`}
                              value={option}
                              checked={userAnswers[index] === option}
                              onChange={() => handleAnswerChange(index, option)}
                            />
                            <label htmlFor={`option${optionIndex}`}>
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <button onClick={handleSubmit} className="quiz-submit-button">
                  Submit
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

function AccordionItem({ title, isOpen, onClick, children }) {
  return (
    <div className="accordion-item">
      <div className="each-course-accordion-title" onClick={onClick}>
        <p>{title}</p>
        <span
          className={isOpen ? "icon-chevron-up" : "icon-chevron-down"}
        ></span>
      </div>
      {isOpen && (
        <div className="each-course-accordion-content">{children}</div>
      )}
    </div>
  );
}
export default Lessons;
