import React from 'react'
import Footer from './Footer'
const Event = () => {
  return (
    <>
      <div className="about_me">
        {/* <div className="team-member" style={{ marginTop: '2rem' }}>
          <img src={require('../Image/staff.jpg')} alt="CEO & Founder" />
          <h3>Tanvi Deshpande</h3>
          <p>Founder & CEO</p>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/tanvi-deshpande-58852a202?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"><i className="fab fa-linkedin-in"></i></a>

          </div>
        </div> */}
        {/* <div className="about_founder" style={{ marginTop: '4rem' }}> */}
          {/* <p>Hi there! I'm Tanvi Deshpande, and I'm thrilled to share a bit about myself. I'm fluent in German at the C2 level and have a knack for teaching the language to a wide range of learners. Whether you're a teacher, doctor, engineer, or from any other profession, I'm here to help you master German with ease and confidence. <br />
            With a background in mechanical engineering, I bring a unique perspective to my teaching. I blend technical knowledge with language instruction to create a well-rounded learning experience. This means I can help you understand and use German not just in everyday conversations but also in professional and technical contexts.
          </p> */}
          {/* <button>My Resume</button> */}
        </div>

      <form>
        <fieldset>
          <legend>About Our Events</legend>
          <div className="fieldContent">
            We are a passionate team dedicated to making language learning easy and enjoyable for everyone. Our mission is to help you master new languages with confidence and ease, no matter your background or profession. From students and teachers to doctors and engineers, we cater to a wide range of learners with our innovative and engaging teaching methods. Our expert instructors create personalized lessons tailored to your needs, ensuring you gain practical and relevant language skills. Join us and discover the joy of learning a new language, opening doors to new opportunities and connections around the world.
          </div>
        </fieldset>
      </form>

      <section className="vision">
        <div className="leftvision">
          <h2>Event Name</h2> <br />
          <p>A short Information about Event, Lorem ipsum dolor sit amet consectetur, adipisicing elit. Doloremque odio cum natus doloribus nesciunt amet quos omnis quidem neque accusantium. Lorem ipsum dolor sit amet.</p>
        </div>
        <div className="rightvision">
          <img src={require("../Image/event1.jpg")} alt="" />
        </div>
      </section>
      <section className="vision">
        <div className="rightvision">
          <img src={require("../Image/event2.jpg")} alt="" />
        </div>
        <div className="leftvision">
          <h2>Other Event</h2> <br />
          <p>Information about the other event Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam, ducimus ab delectus magni sit ipsa. Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facilis, incidunt.</p>
        </div>
      </section>
      <section className="vision">
        <div className="leftvision">
          <h2>Event Name</h2> <br />
          <p>Information about the event Lorem ipsum dolor sit amet consectetur adipisicing elit. Assumenda optio quis commodi iste culpa quasi reiciendis delectus id magnam quos dignissimos suscipit exercitationem repellendus, maxime perspiciatis eius, quibusdam est nisi.</p>
        </div>
        <div className="rightvision">
          <img src={require("../Image/event1.jpg")} alt="" />
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Event