import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import LimitWords from "./LimitWords";
const AllCoursePage = () => {
  const [course, setCourse] = useState([]);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    getCourse();
  }, []);

  const getCourse = async () => {
    setSpinner(true);
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/course/get-all-course`
    );
    const res = await response.json();
    setSpinner(false);
    setCourse(res.data);
  };
  const ratingCall = (count) => {
    const stars = [];
    for (let i = 0; i < count; i++) {
      stars.push(
        <span key={i} style={{ color: "gold" }}>
          &#9733;
        </span>
      );
    }
    return stars;
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="heading-notes" >
        <h1>Courses</h1>
      </div>
      <div className="notes " style={{flexWrap:'wrap'}}>
        {course &&
          course.map((elem, id) => {
            return (
              <div key={id}>
                <div className="note notes-box " style={{height:'22rem'}}>
                  <img src={elem.coverImage} alt="" />
                  <h2>{elem.courseName}</h2>
                  <p>
                    <b>Instructor:</b> {elem.teacherName}
                  </p>
                  <p>
                    {ratingCall(elem.rating)} ({elem.rating})
                  </p>
                  <p className="rate">
              <b>Price: </b>Rs. {elem.price}/-{" "}
              <span style={{ fontSize: "0.7em", fontWeight: "bold" }}>
                {elem.discount}%off
              </span>
            </p>
                  <div className="button-delete-notes">
                    <Link to={`/course/${elem._id}`}>
                      <button>Explore Now</button>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        
      </div>
    </>
  );
};

export default AllCoursePage;
