import React, { useEffect, useState } from 'react'
import '../CSS/Staff.css'

const Staff = () => {
    const [teacher, setTeacher] = useState([]);
    const getAllTeacher = async () => {
        const response = await fetch('https://ed-tech-backend-fsnw.onrender.com/api/v1/teacher-profile/get-all-teacher-profile')
        const res = await response.json()
        setTeacher(res.data)
    }
    useEffect(() => {
        getAllTeacher();
    })
    const ratingCall = (count) => {
        const stars = [];
        for (let i = 0; i < count; i++) {
            stars.push(<span key={i} style={{ color: 'gold' }}>&#9733;</span>);
        }
        return stars;
    }
    return (

        <>
            <div className="staff certified">
                <h1>Our Best Team</h1>
                <hr />
            </div>
            <div className="staff_cards">
                {teacher.map((elem) => {
                    return <div className="card_s">
                        <img src={elem.teacherImage} alt="staff img" />
                        <h1>{elem.fullName}</h1>
                        <p>{elem.designation}</p>
                        <h2>Experience : {elem.experience}</h2>
                        <p>{ratingCall(elem.rating)} ({elem.rating})</p>
                    </div>
                })}
            </div>
        </>

    )
}

export default Staff