import React from 'react'
import '../CSS/discountHeader.css'
const DiscountHeader = () => {
  return (
    <div className="top_header">
    <h1>Enroll now and get an exclusive learning discount!</h1>
</div>
  )
}

export default DiscountHeader