import React, { useEffect, useState } from "react";
import "../CSS/Notes.css";
import Spinner from "./Spinner";
import LimitWords from "./LimitWords";
const Notes = () => {
  const [notes, setNotes] = useState([]);
  const [spinner, setSpinner] = useState(false);
  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    setSpinner(true);
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/notes/`
    );
    const res = await response.json();
    setSpinner(false);
    setNotes(res.data);
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="heading-notes">
        <h1>Notes</h1>
      </div>
      <div className="notes ">
        {notes &&
          notes.map((elem, id) => {
            return (
              <div key={id}>
                <div className="note notes-box">
                  <img src={elem.bookImage} alt="" />
                  <h2>{elem.bookName}</h2>
                  <p>
                    <LimitWords text={elem.bookDescription} limit={30} />
                  </p>
                  <div className="button-delete-notes">
                    <a href={elem.bookPdf} target="_singh">
                      <button>See Notes</button>
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Notes;
