import React, { useState } from 'react';
import '../CSS/Contact.css';
import Spinner from './Spinner';

const ContactUs = (props) => {
  const [spinner, setSpinner] =  useState(false)
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async(e) => {
    setSpinner(true)
    e.preventDefault();
    console.log(formData);
    const response = await fetch(`https://ed-tech-backend-fsnw.onrender.com/api/v1/contact`,{
      method:'POST',
      headers:{
        'Conten-Type':'application/json'
      },
      body: JSON.stringify({name: formData.name, number:formData.number, email:formData.email, message:formData.message})
    })
    const res = response.json();
    setFormData({
      name: '',
      number: '',
      email: '',
      message: ''
    });
    if(res.success){
    props.showAlert(res.message, 'success')
    }
    setSpinner(false)
  };

  return (
    <div className="contact-container">
      {spinner && <Spinner/>}
      <h2>Contact Us</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <input
          type="tel"
          name="number"
          placeholder="Your Phone Number"
          value={formData.number}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Your Email Address"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <button type="submit">Send Message</button>
      </form>
    </div>
  );
};

export default ContactUs;
