import React, { useState } from 'react'
import '../CSS/SignUp.css'
import { Link, useNavigate } from 'react-router-dom'
const SignUp = () => {
    const navigate = useNavigate();
    const [showPaassword, setShowPassword] = useState(false)
    const [signUp, setSignUp] = useState({
        fullName:'',
        number:'',
        email:'',
        password:''
    })

    const onChange = (e)=>{
        setSignUp({...signUp, [e.target.name]:e.target.value})
    }
    
    const submitForm = async(e)=>{
        e.preventDefault();

        const response = await fetch('https://ed-tech-backend-fsnw.onrender.com/api/v1/user/sign-up',{
            method:'POST',
            headers:{
                'Content-Type':"application/json"
            },
            body:JSON.stringify({fullName:signUp.fullName, number:signUp.number, email:signUp.email, password:signUp.password})
        })
        const res = await response.json();
        console.log(res)
        localStorage.setItem('authToken', res.data.token)
        if(res.success){
            navigate('/')
        }
        console.log(res)
    }
  return (
    <div class="main-container">
    <div class='login_img'>
        <img src={require("../Image/img1.png")} alt=""/>
    </div>
    <div className="form-container signup">
    <form id="login-form" className="form" onSubmit={submitForm}>
        <p className="form-head">SignUp</p>
        <input type="text" name='fullName' id="fullName" onChange={onChange} placeholder="Full Name" required/>
        <input type="text" name='email' id="email" onChange={onChange} placeholder="Email" required/>
        <input type="text" name='number' id="number" onChange={onChange} placeholder="Number" required/>
        <div className="password-input">

        <input type={showPaassword?'text':'password'} name='password' id="password" onChange={onChange} placeholder="Password" required/>
        <div className="show-password">
        {showPaassword?<i class="fa-regular fa-eye" onClick={()=>setShowPassword(!showPaassword)}></i>:
                    <i class="fa-regular fa-eye-slash" onClick={()=>setShowPassword(!showPaassword)}></i>}
        </div>
        </div>
        <button className='login-button' type="submit">Create Account</button>
        <p className="signup-text">Already have an Account? <Link to="/login" className="link">Login</Link></p>
    </form>
</div>
</div>

  )
}

export default SignUp