import React from 'react'
import '../CSS/AboutMe.css'
import Footer from './Footer'
const About = () => {
  return (
    <>
      <section className="team">
        <div className="container">
          <h2>About Me</h2>
          <hr />
          <div className="about_me">
            <div className="team-member">
              <img src={require('../Image/staff.jpg')} alt="CEO & Founder" />
              <h3>Tanvi Deshpande</h3>
              <p>Founder & CEO</p>
              <div className="social-icons">
                <a href="https://www.linkedin.com/in/tanvi-deshpande-58852a202?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app" target="_blank"><i className="fab fa-linkedin-in"></i></a>
                
              </div>
            </div>
            <div className="about_founder">
              <p>Hi there! I'm Tanvi Deshpande, and I'm thrilled to share a bit about myself. I'm fluent in German at the C2 level and have a knack for teaching the language to a wide range of learners. Whether you're a teacher, doctor, engineer, or from any other profession, I'm here to help you master German with ease and confidence. <br />
                With a background in mechanical engineering, I bring a unique perspective to my teaching. I blend technical knowledge with language instruction to create a well-rounded learning experience. This means I can help you understand and use German not just in everyday conversations but also in professional and technical contexts.
              </p>
              <br />
              <p>I design customized language programs that include relevant terminology and concepts specific to your field. My goal is to make learning German practical and directly applicable to your work. I use interactive and engaging methods to keep lessons interesting and ensure that you actively participate and understand the material. <br />

                In my classes, you'll find real-world examples and case studies that make learning German relevant and useful. I focus on developing your communication skills, so you feel prepared to use German effectively in your career. <br />
                Whether you're looking to improve your language skills for professional growth or personal interest, I'm here to support you every step of the way. Let's embark on this language learning journey together!
              </p>
              {/* <button>My Resume</button> */}
            </div>
          </div>
        </div>
      </section>
      <div className="whowelower">
        <form>
          <fieldset>
            <legend>Who are we?</legend>
            <div className="fieldContent">
              We are a passionate team dedicated to making language learning easy and enjoyable for everyone. Our mission is to help you master new languages with confidence and ease, no matter your background or profession. From students and teachers to doctors and engineers, we cater to a wide range of learners with our innovative and engaging teaching methods. Our expert instructors create personalized lessons tailored to your needs, ensuring you gain practical and relevant language skills. Join us and discover the joy of learning a new language, opening doors to new opportunities and connections around the world.
            </div>
          </fieldset>
        </form>
        <section className="vision">
          <div className="leftvision">
            <h2>Our Vision</h2> <br />
            <p>Our vision is to become a global leader in language education, empowering individuals from all walks of life to connect, communicate, and thrive in a multilingual world. We aim to create a world where language barriers no longer exist, and everyone has the opportunity to unlock their full potential through the power of language learning.</p>
          </div>
          <div className="rightvision">
            <img src={require("../Image/our_vision.png")} alt="" />
          </div>
        </section>
        <section className="vision">
          <div className="rightvision">
            <img src={require("../Image/our_mission.png")} alt="" />
          </div>
          <div className="leftvision">
            <h2>Our Mission</h2> <br />
            <p>Our mission is to provide high-quality, accessible, and engaging language education that meets the diverse needs of our learners. We strive to create a supportive and innovative learning environment where students can achieve their language goals with confidence. Through personalized instruction, practical application, and a commitment to excellence, we aim to inspire a lifelong love of languages and foster cross-cultural understanding and communication.</p>
          </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default About