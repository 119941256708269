import React, { useState } from "react";
import "../CSS/ConfirmPayment.css";
import Spinner from "./Spinner";
import { useNavigate } from "react-router-dom";
const ConfirmPayment = (props) => {
  const navigate = useNavigate();
  const CourseId = window.location.pathname.split("/").pop();
  const [spinner, setSpinner] = useState(false);
  const [confirmationFile, setConfirmationFile] = useState();
  const [data, setdata] = useState({
    name: "",
    number: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinner(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("number", data.number);
    formData.append("email", data.email);
    formData.append("courseId", CourseId);
    formData.append("paymentConfirmation", confirmationFile);
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/payment-confirmation/`,
      {
        method: "POST",
        headers:{
          'authToken':localStorage.getItem('authToken')
        },
        body: formData,
      }
    );
    const json = await response.json();
    setSpinner(false)
    props.showAlert(json.message, "success");
    navigate("/");
  };
  const onFileChange = async (e) => {
    setConfirmationFile(e.target.files[0]);
  };
  return (
    <div className="confirm-payment-page">
      {spinner && <Spinner />}
      <h1>Confirmation Of Payment</h1>
      <form className="payment-form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          autoComplete="off"
          placeholder="Your Name"
          value={data.name}
          onChange={handleChange}
          required
        />
        <input
          type="number"
          name="number"
          autoComplete="off"
          placeholder="Your Phone Number"
          value={data.number}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          autoComplete="off"
          placeholder="Your Email Address"
          value={data.email}
          onChange={handleChange}
          required
        />
        <div className="payment-file-input">
          <input
            style={{ border: "none", cursor: "pointer", width: "19rem" }}
            type="file"
            name="paymentCofirmation"
            id="paymentCofirmation"
            onChange={onFileChange}
          />
          ( <p>Upload Screnshot</p>)
        </div>
        <button type="submit">Submit Details</button>
      </form>
    </div>
  );
};

export default ConfirmPayment;
