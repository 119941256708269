import React from 'react'
import HeroSection from './HeroSection'
import Category from './Category'
import ExamCategory from './ExamCategory'
import Testimonials from './Testimonials'
import Courses from './Courses'
import LResource from './LResource'
import Certified from './Certified'
import Staff from './Staff'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'
import ContactUs from './ContactUs'

const Home = (props) => {
  return (
    <>
      <ScrollToTop />
      <HeroSection />
      <Category />
      <ExamCategory />
      <Testimonials />
      <Courses />
      <LResource />
      <Certified />
      <Staff />
      <ContactUs showAlert={props.showAlert} />
      <Footer />
    </>
  )
}

export default Home