import React, { useEffect, useState } from "react";
import "../CSS/CoursePriceDetails.css";
import Footer from './Footer'
import { useNavigate } from "react-router-dom";
const LiveCoursePriceDetails = () => {
  const navigate = useNavigate();
  const [paymentOption, setPaymentOption] = useState(false);
  const CourseId = window.location.pathname.split("/").pop();
  const [courseDetails, setCourseDetails] = useState({
    id: "",
    courseName: "",
    description: "",
    price: "",
    couponCode: "",
    duration: "",
    coverImage: "",
    discount: "",
    teacherName: "",
    rating: "",
  });
  const getCourseDetails = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/live-course/get-live-course/${CourseId}`
    );
    const res = await response.json();
    const data = res.data;
    setCourseDetails({
      id: data._id,
      courseName: data.courseName,
      description: data.description,
      price: data.price,
      couponCode: data.couponCode,
      duration: data.duration,
      coverImage: data.coverImage,
      discount: data.discount,
      teacherName: data.teacherName,
      rating: data.rating,
    });
  };

  useEffect(() => {
    getCourseDetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },[]);
  return (
    <>
   <div className="main-container-payment" >
      <div className="payment-container" id="myDIV">
        <div className="left-column-payment">
          <div className="receiver-info">
            <h2>Selected Courses :</h2>
          </div>
          <div className="selected-course">
            <p>
              <b>Course Name: </b> {courseDetails.courseName}
            </p>
            <p>
              <b>Duration:</b> {courseDetails.duration}
            </p>
            <p>
              <b>Price: </b>Rs. {courseDetails.price}
            </p>
          </div>
        </div>
        <div className="right-column">
          <div className="receiver-info">
            <h3>Invoice:</h3>
          </div>
          <div className="payment-price-details">
            <p className="selected-course-details">
              <b>Cost of course :</b> Rs. {courseDetails.price}/-
            </p>
            <p className="selected-course-details">
              <b>Coupun Code Discount :</b> {courseDetails.discount}%off
            </p>
            <p className="selected-course-details">
              <b>Total Amount Payable :</b> Rs.
              Rs. {Math.ceil(courseDetails.price - courseDetails.price * ((courseDetails.discount) / 100))}/-
            </p>
          </div>
          <div className="payment-button" onClick={()=>setPaymentOption(true)}>
            <p >Pay Now</p>
          </div>
        </div>
      </div>
      {paymentOption && (
        <>
          <div className="payment-container" id="myOtherDIV">
            <div className="left-column-payment">
              <p>Make a Payment Via QR Code or UPI Id:</p>
              <div className="qrcode">
                <img src={require("../Image/QR.png")} alt="" />
              </div>
              <div className="payApp">
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/color/48/phone-pe.png"
                  alt="phone-pe"
                />
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/3d-fluency/94/paytm.png"
                  alt="paytm"
                />
                <img
                  width="48"
                  height="48"
                  src="https://img.icons8.com/color/48/google-pay.png"
                  alt="google-pay"
                />
              </div>
              <div className="upi-id">
                <h4>
                  UPI ID : 8920844922@ibl
                </h4>
              </div>
            </div>
            <hr />
            <div className="right-column">
              <div className="receiver-info">
                <h3>User Bank Details :</h3><br />
                <p className="details">
                  <b>Username : </b> John Doe
                </p>
                <p className="details">
                  <b>Bank Name : </b>Punjab National Bank
                </p>
                <p className="details">
                  <b>Account Number : </b> 1234567890
                </p>
                <p className="details">
                  <b>IFSC Code : </b> ABCD1234567
                </p>
              </div>
            </div>
          </div>
    <div className="confirm-payment-link">
    <p>If you have succsefully Paid then click the button</p>
    <a href="https://docs.google.com/forms/d/1tqpxuagjGXJtyrOJrlf4W-TuNAGNrpYM35aGfzJNfP4/" target="_singh"><button onClick={()=>navigate('/')}>Click Here</button></a>
    </div>
        </>
      )}
    </div>
    <Footer/>
  </>
  );
};

export default LiveCoursePriceDetails;
