import React from 'react'
import '../CSS/Certified.css'

const Certified = () => {
    return (
        <>
            <div className="certified ">
                <h1>Certified by most trusted Organisations</h1>
                <hr />
                <div className="badge-images">
                <img src={require("../Image/badge 1.png")} alt="Startup Images" />
                <img src={require("../Image/badge 2.png")} alt="Startup Images" />
                </div>
            </div>
        </>
    )
}

export default Certified