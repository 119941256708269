import React, { useState, useEffect } from "react";
import "../CSS/Navbar.css";
import { Link, useNavigate } from "react-router-dom";
const Navbar = (props) => {
  const [profile, setProfile] = useState("");
  const callApiProfile = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/user/get-user`,
      {
        headers: {
          authToken: localStorage.getItem("authToken"),
        },
      }
    );
    const resData = await response.json();
    const data = resData.data;
    setProfile(data.profile);
  };
  useEffect(() => {
    callApiProfile();
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    props.showAlert("Successfully Logged Out!!!", "success");
    localStorage.removeItem("authToken");
    navigate("/");
  };

  const showNav = () => {
    const respoRespoId = document.getElementById("respo-nav-id");
    const showNav = document.getElementById("show-nav");
    const hideNav = document.getElementById("close-nav");
    hideNav.style.left = "6rem";
    respoRespoId.style.left = "-1rem";
  };
  const hideNav = () => {
    const respoRespoId = document.getElementById("respo-nav-id");
    const showNav = document.getElementById("show-nav");
    const hideNav = document.getElementById("close-nav");
    hideNav.style.left = "-10rem";
    respoRespoId.style.left = "-20rem";
  };  
  return (
    <nav className="navbar">
      <div className="nav-respo-items">
        <i class="fa-solid fa-bars" id="show-nav" onClick={showNav}></i>
        <i class="fa-solid fa-xmark" id="close-nav" onClick={hideNav}></i>
      </div>
      <div className="respo-nav">
        <div className="left_nav">
          <ul id="respo-nav-id">
            <li>
              <Link to="/" >
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" loading="lazy">
                About
              </Link>
            </li>
            <li>
              <Link to="/courses">Course</Link>
            </li>
            <li>
              <Link to="/event">Events</Link>
            </li>
          </ul>
        </div>
        <div className="left-leftnav">
          <div className="logo">
            <img src={require("../Image/logo-top.png")} alt="" />
            <div className="logo-content">
              <h4>Tanvi Deshpande Academy</h4>
              <p>Discover.Learn.Acheive</p>
            </div>
          </div>
        </div>
      </div>
      <div className="right_nav">
        <div className="right_nav_item">
          {!localStorage.getItem("authToken") ? (
            <Link to="/login">
              <button>Login</button>
            </Link>
          ) : (
            <>
              <div
                className="profile-dropdown"
                onClick={() => setShowDropdown(!showDropdown)}
              >
                <img
                  src={profile ? profile : require("../Image/profile.webp")}
                  alt="Profile"
                  className="profile-image"
                />
                {showDropdown && (
                  <div className="dropdown-content">
                    <Link to="/profile">My Profile</Link>
                    <Link to="/login" onClick={handleLogout}>
                      Log Out
                    </Link>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
