import React, { useEffect, useState } from "react";
import "../CSS/EachCourse.css";
import Footer from "./Footer";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
const EachCourse = (props) => {
  const navigate = useNavigate();
  const CourseId = window.location.pathname.split("/").pop();
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeSubChapterIndex, setSubChapterActiveIndex] = useState(null);
  const [courseDetails, setCourseDetails] = useState({
    id: "",
    courseName: "",
    description: "",
    price: "",
    couponCode: "",
    duration: "",
    coverImage: "",
    discount: "",
    teacherName: "",
    rating: "",
  });
  const [chapterDetails, setChapterDetails] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [subChapter, setSubChapter] = useState([]);
  const toggleAccordion = (index, id) => {
    getSubChapterDetails(id);
    setActiveIndex(activeIndex === index ? null : index);
  };

  const getCourseDetails = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/course/get-course/${CourseId}`
    );
    const res = await response.json();
    const data = res.data;
    setCourseDetails({
      id: data._id,
      courseName: data.courseName,
      description: data.description,
      price: data.price,
      couponCode: data.couponCode,
      duration: data.duration,
      coverImage: data.coverImage,
      discount: data.discount,
      teacherName: data.teacherName,
      rating: data.rating,
    });
  };
  const getChapterDetails = async () => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/chapter/get-all-chapter/${CourseId}`
    );
    const res = await response.json();
    const data = res.data;
    setChapterDetails(data);
  };
  const getSubChapterDetails = async (id) => {
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/sub-chapter/get-all-chapter/${id}`
    );
    const res = await response.json();
    const data = res.data;
    setSubChapter(data);
  };
  useEffect(() => {
    getCourseDetails();
    getChapterDetails();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const enrollcourse = async () => {
    setSpinner(true);
    const response = await fetch(
      `https://ed-tech-backend-fsnw.onrender.com/api/v1/user/check-course`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: localStorage.getItem("authToken"),
        },
        body: JSON.stringify({ courseId: courseDetails.id }),
      }
    );
    const res = await response.json();
    console.log(res);
    if (res.success) {
      navigate(`/selected-course/${courseDetails.id}`);
    } else {
      props.showAlert(res.message, "error");
    }
    setSpinner(false);
  };

  return (
    <>
      {spinner && <Spinner />}
      <div className="course-hero-section">
        <div className="left-course-hero-section">
          <span>Online</span>
          <h1>{courseDetails.courseName}</h1>
          <p>{courseDetails.description}</p>
          <div className="overview-details-each-course">
            <div className="each-overview-details-each-course">
              <i class="fa-solid fa-user"></i>
              <p>Instructor: {courseDetails.teacherName}</p>
            </div>
            <div className="each-overview-details-each-course">
              <i class="fa-solid fa-users"></i>
              <p> 20,000+ Learners</p>
            </div>
            <div className="each-overview-details-each-course">
              <i class="fa-solid fa-calendar-days"></i>
              <p>Duration: {courseDetails.duration}</p>
            </div>
          </div>
          <div className="rating-course">
            <p>
              <span>
                {courseDetails.rating} <i class="fa-solid fa-star"></i>
              </span>
              (5 ratings) 10 students
            </p>
          </div>
        </div>
        <div className="right-course-hero-section">
          <div className="image-right-course-hero-section">
            <img src={courseDetails.coverImage} alt="" />
          </div>
          <div className="content-right-course-hero-section">
            <p>
              Price :
              <b>
                Rs.{" "}
                {Math.ceil(
                  courseDetails.price -
                    courseDetails.price * (courseDetails.discount / 100)
                )}
              </b>
              <span> {courseDetails.discount}%off</span>
            </p>
            <hr />
            <button
              onClick={() =>
                localStorage.getItem("authToken")
                  ? enrollcourse()
                  : navigate("/login")
              }
            >
              Enroll Now <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      <div className="all-about-course">
        <div className="left-all-about-course">
          <h1>About Us</h1>
          <p>
            In my classes, you'll find real-world examples and case studies that
            make learning German relevant and useful. I focus on developing your
            communication skills, so you feel prepared to use German effectively
            in your career. Whether you're looking to improve your language
            skills for professional growth or personal interest, I'm here to
            support you every step of the way. Let's embark on this language
            learning journey together!
          </p>
          <h1>Course Curriculum</h1>
          <div className="short-details-of-course">
            <div className="each-short-details-of-course">
              <i class="fa-regular fa-note-sticky"></i>
              47 Chapters
            </div>
            <div className="each-short-details-of-course">
              <i class="fa-regular fa-clock"></i>5 weeks duration
            </div>
          </div>
          {/* Accordian */}
          <div className="accordion">
            {chapterDetails.map((elem, index) => {
              return (
                <AccordionItem
                  title={`${elem.chapterIndex} - ${elem.title}`}
                  isOpen={activeIndex === index}
                  onClick={() => toggleAccordion(index, elem._id)}
                >
                  {subChapter.map((elem, index) => {
                    return (
                      <ul>
                        <li>{`${elem.subChapterIndex} - ${elem.subChapterTitle}`}</li>
                      </ul>
                    );
                  })}
                </AccordionItem>
              );
            })}
          </div>
        </div>
        <div className="right-all-about-course">
          <div className="each-right-all-about-course">
            <h2>This Course Includes: </h2>
            <li>10.4 hours on-demand videos</li>
            <li>3 study materials</li>
            <li>Full lifetime Access</li>
            <li>Certification of Completion</li>
            <p>
              <span>Price: </span>
              <b>
                Rs.{" "}
                {Math.ceil(
                  courseDetails.price -
                    courseDetails.price * (courseDetails.discount / 100)
                )}
              </b>
              <span>
                <span style={{ textDecoration: "line-through" }}>
                  Rs. {courseDetails.price}
                </span>
                - {courseDetails.discount} %off
              </span>
            </p>
            <hr />
            <button
              onClick={() =>
                localStorage.getItem("authToken")
                  ? enrollcourse()
                  : navigate("/login")
              }
            >
              Enroll Now <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
          <div className="bottom-each-right-all-about-course">
            <button>
              Financial Aid <i class="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>

      {/* <div className="course-fee-structure">
        <h1>Course Fee Structure</h1>
        <table className="custom-table">
          <tbody>
            <tr>
              <td className="border header">Reg Fee</td>
              <td className="border header">Tuition Fee</td>
              <td className="border header">Total Fee</td>
            </tr>
            <tr>
              <td className="border">Rs. 1,500</td>
              <td className="border">Rs. 3,500</td>
              <td className="border">Rs. 5,500</td>
            </tr>
          </tbody>
        </table>
      </div> */}
      <Footer />
    </>
  );
};

function AccordionItem({ title, isOpen, onClick, children }) {
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={onClick}>
        {title}
        <span
          className={isOpen ? "icon-chevron-up" : "icon-chevron-down"}
        ></span>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
}
export default EachCourse;
